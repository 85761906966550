/** @format */

import React from 'react';
import { useSiteData, DataContextProps } from '@context';
import { Modal, Form } from '@components';
import { shareStoryFields } from './share-story';

/**
 * Share You Story Sidebar.
 */
const ShareStorySidebar = (): JSX.Element | null => {
  const { siteData }: DataContextProps = useSiteData();
  if (siteData) {
    const { translations } = siteData;

    /**
     * Render.
     */
    return (
      <div className="share-story-container">
        <h2 className="font-size-md font-bold letter-spacing-md">
          {translations.share_your_story_title}
        </h2>
        <p>{translations.share_your_story_description}</p>
        <Modal
          title={translations?.share_your_story}
          buttonText={translations?.share_your_story}
          showTitle={false}
        >
          <Form
            formFields={shareStoryFields}
            formTitle={translations?.share_your_story}
            formDisclaimer={translations?.share_story_disclaimer}
            buttonText={translations?.submit}
            formId="submit-story-form"
          />
        </Modal>
      </div>
    );
  }
  return null;
};
export default ShareStorySidebar;
