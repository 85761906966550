import React, { useState, useEffect } from 'react';
import * as RadixForm from '@radix-ui/react-form';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import type { FieldDetails } from './form-field';
import FormField from './form-field';
import { useSiteData, DataContextProps } from '@context';
import { Ripple } from '@base-components';
import { capitalize } from '@utilities';
import { Loader } from '@components';
import axios from 'axios';

/**
 * Form.
 */
const Form: React.FC<{
  formFields: FieldDetails[];
  formTitle: string;
  formIntro?: string;
  formDisclaimer?: string;
  formId: string;
  buttonText: string;
  isStorybookPreview?: boolean;
}> = ({
  formFields,
  formTitle,
  formIntro,
  formDisclaimer,
  formId,
  buttonText,
  isStorybookPreview = false,
}) => {
  const { siteData }: DataContextProps = useSiteData();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [successfulSubmission, setSuccessfulSubmission] =
    useState<boolean>(false);

  /**
   * Check for disclaimer.
   */
  useEffect(() => {
    if (!formDisclaimer) {
      setSubmitDisabled(false);
    }
  }, [formDisclaimer]);

  if (siteData) {
    const { translations } = siteData;

    /**
     * Handle Submit.
     */
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = Object.fromEntries(new FormData(event.currentTarget));
      if (!isStorybookPreview && data) {
        setLoading(true);

        const formData = [];
        for (const [key, value] of Object.entries(data)) {
          if (key !== 'bot-field') {
            formData.push({
              title: capitalize(key).replace(/-/g, ' '),
              value: value,
            });
          }
        }
        const formattedData = {
          mail: {
            to: 'empowerme@varian.com',
          },
          body: formData,
        };

        // Send email via Varian mailman
        axios
          .post(`${window.location}api/emailer`, formattedData, {
            // @ts-ignore: next-line
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          })
          .then((response) => {
            const success = response?.data?.successfulSubmission ?? false;
            if (success) {
              setSuccessfulSubmission(true);
            } else {
              setSubmitError(true);
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        /**
         * Test forms in storybook without actually submitting data.
         */
        setSuccessfulSubmission(true);
        alert(
          `Submission data: \n\n ${JSON.stringify(
            data
          )} \n\nThe form will reset after closing this alert.`
        );
        setTimeout(() => {
          setSuccessfulSubmission(false);
        }, 500);
      }
    };

    /**
     * Render success message.
     */
    if (successfulSubmission) {
      return (
        <div className="successful-submission center-vertically small-gaps">
          <h2>{translations?.thank_you}</h2>
          <p>{translations?.successful_submission}</p>
        </div>
      );
    }

    /**
     * Render error message.
     */
    if (submitError) {
      return (
        <div className="submit-error center-vertically small-gaps">
          <h2>{translations?.error}</h2>
          <p>{translations?.submission_failure}</p>
        </div>
      );
    }

    /**
     * Loading message.
     */
    if (loading) {
      return <Loader />;
    }

    /**
     * Render form.
     */
    if (formFields) {
      return (
        <RadixForm.Root
          className="form-wrapper"
          onSubmit={(event) => handleSubmit(event)}
          name={formId}
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          {/**
           * Setting title to hidden do to duplicate titles via modal.
           * Needed for better control over sucess, error and loading messages.
           */}
          {formTitle && <h2 aria-hidden={true}>{formTitle}</h2>}
          {formIntro && formIntro !== '' && <p>{formIntro}</p>}
          {formFields.length > 0 &&
            formFields.map((field, index) => {
              return <FormField fieldDetails={field} key={index} />;
            })}

          {formDisclaimer && formDisclaimer !== '' && (
            <div className="disclaimer">
              <Checkbox.Root
                className="checkbox-wrapper"
                id="disclaimer"
                onCheckedChange={() => setSubmitDisabled(!submitDisabled)}
              >
                <Checkbox.Indicator className="checkbox-indicator">
                  <CheckIcon />
                </Checkbox.Indicator>
              </Checkbox.Root>
              <label htmlFor="disclaimer">{formDisclaimer}</label>
            </div>
          )}

          <div className="button-container">
            <RadixForm.Submit asChild>
              <button
                type="submit"
                className="primary"
                disabled={submitDisabled}
              >
                {buttonText}
                {!submitDisabled && <Ripple />}
              </button>
            </RadixForm.Submit>
          </div>

          {/* Netlify honeypot field */}
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value={formId} />
        </RadixForm.Root>
      );
    }
  }
  return null;
};
export default Form;
