/** @format */

import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  Pagination,
  Configure,
} from 'react-instantsearch';
import { useSiteData, DataContextProps } from '@context';
import NoResultsBoundary from './no-results-boundary';
import SearchBox from './search-box';
import SearchResult from './search-result';

/**
 * Search.
 */
const Search = (): JSX.Element | null => {
  const { siteData }: DataContextProps = useSiteData();
  if (siteData) {
    const { currentLocale } = siteData;
    const searchClient = algoliasearch(
      // @ts-ignore: next-line
      process.env.GATSBY_ALGOLIA_APP_ID,
      // @ts-ignore: next-line
      process.env.GATSBY_ALGOLIA_SEARCH_KEY
    );

    /**
     * Render.
     */
    return (
      <div className="search-results-container">
        <InstantSearch
          searchClient={searchClient}
          indexName="crawler_Varian Patient Site"
        >
          <SearchBox />
          <NoResultsBoundary>
            {/* @ts-ignore: next-line */}
            <Configure hitsPerPage={10} filters={`language:${currentLocale}`} />
            <Hits hitComponent={SearchResult} />
            <Pagination />
          </NoResultsBoundary>
        </InstantSearch>
      </div>
    );
  }
  return null;
};
export default Search;
