import React from 'react';

/**
 * Loader.
 */
const Loader = (): JSX.Element => {
  return (
    <div className="loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
export default Loader;
